class LatepointPaymentsRazorpayAddon {

	// Init
	constructor(razorpayKey){
		this.razorpayKey = razorpayKey;
		this.razorpayCore = null;
		this.razorpayOrderId = null;
		this.ready();
	}

	ready(){
		jQuery(document).ready(() => {
			jQuery('body').on('latepoint:submitBookingForm', '.latepoint-booking-form-element', (e, data) => {
	      if(!latepoint_helper.demo_mode && data.is_final_submit && data.direction == 'next'){
	      	let payment_method = jQuery(e.currentTarget).find('input[name="booking[payment_method]"]').val();
	      	switch(payment_method){
	      		case 'razorpay_checkout':
			      	latepoint_add_action(data.callbacks_list, () => {
								return this.initPaymentModal(jQuery(e.currentTarget), payment_method);
				      });
	      		break;
	      	}
	      }
			});

			jQuery('body').on('latepoint:nextStepClicked', '.latepoint-booking-form-element', (e, data) => {
	      if(!latepoint_helper.demo_mode && (data.current_step == 'payment')){
	      	let payment_method =  jQuery(e.currentTarget).find('input[name="booking[payment_method]"]').val();
	      	switch(payment_method){
	      		case 'razorpay_checkout':
			      	latepoint_add_action(data.callbacks_list, () => {
			      	});
	      		break;
	      	}
	      }
			});

			jQuery('body').on('latepoint:initPaymentMethod', '.latepoint-booking-form-element', (e, data) => {
				if(data.payment_method == 'razorpay_checkout'){
					let $booking_form_element = jQuery(e.currentTarget);
				  let $latepoint_form = $booking_form_element.find('.latepoint-form');
					latepoint_add_action(data.callbacks_list, () => {
						latepoint_show_next_btn($booking_form_element);
				    // $booking_form_element.removeClass('step-content-loaded').addClass('step-content-loading');
			      // $latepoint_form.submit();
						// return this.createOrder($booking_form_element, data.payment_method);
	      	});
				}
			});

			jQuery('body').on('latepoint:initStep:payment', '.latepoint-booking-form-element', (e, data) => {
			}); 
		});
	}

	createOrder($booking_form_element, payment_method){
    let deferred = jQuery.Deferred();
    let $latepoint_form = $booking_form_element.find('.latepoint-form');
    $booking_form_element.removeClass('step-content-loaded').addClass('step-content-loading');
    var data = { 
    	action: 'latepoint_route_call', 
    	route_name: latepoint_helper.razorpay_create_order_route, 
    	params: $booking_form_element.find('.latepoint-form').serialize(), 
    	layout: 'none', 
    	return_format: 'json' 
    }
    jQuery.ajax({
      type : "post",
      dataType : "json",
      url : latepoint_helper.ajaxurl,
      data : data,
      success: (data) => {
        if(data.status === "success"){
        	this.razorpayOrderId = data.message;
        	$latepoint_form.submit();
        	deferred.resolve();
        }else{
        	deferred.reject({message: data.message});
        }
      },
      error: function(request, status, error){
      	deferred.reject({message: result.error.message});
      }
    });

	  return deferred;
	}

	initPaymentModal($booking_form_element, payment_method) {
    let deferred = jQuery.Deferred();
    let $latepoint_form = $booking_form_element.find('.latepoint-form');
    var data = { 
    	action: 'latepoint_route_call', 
    	route_name: latepoint_helper.razorpay_payment_options_route, 
    	params: $booking_form_element.find('.latepoint-form').serialize(), 
    	layout: 'none', 
    	return_format: 'json' 
    }
    jQuery.ajax({
      type : "post",
      dataType : "json",
      url : latepoint_helper.ajaxurl,
      data : data,
      success: (data) => {
        if(data.status === "success"){
        	if(data.amount > 0){
	        	data.options.order_id = data.razorpay_order_id;
	        	data.options.handler = (response) => {
	        		let $payment_token_field = $booking_form_element.find('input[name="booking[payment_token]"]');
	        		if($payment_token_field.length){
						  	$booking_form_element.find('input[name="booking[payment_token]"]').val(response.razorpay_payment_id);
	        		}else{
	        			// create payment token field if it doesn ot exist (when payment step is skipped)
	        			$booking_form_element.find('.latepoint-booking-params-w').append('<input type="hidden" value="' + response.razorpay_payment_id +'" name="booking[payment_token]" class="latepoint_payment_token"/>');
	        		}
		        	deferred.resolve();
	        	};
	        	data.options.modal = {ondismiss: () => {
	        		deferred.reject({message: 'Checkout form closed'});	
	        	}};
						this.razorpayCore = new Razorpay(data.options);
				    this.razorpayCore.open();
        	}else{
        		// free booking
        		deferred.resolve();
        	}
        }else{
        	deferred.reject({message: data.message});
        }
      },
      error: function(request, status, error){
      	deferred.reject({message: result.error.message});
      }
    });
	  return deferred;
	}


	confirmCardPayment($booking_form_element, intent_secret, payment_method){
    let deferred = jQuery.Deferred();
		this.razorpayCore.confirmCardPayment(
		  intent_secret,
		  {
		    payment_method: payment_method.id
		  }
		).then((result) => {
		  if (result.error) {
      	deferred.reject({message: result.error.message, send_to_step: 'payment'});
		  } else {
    		let $payment_token_field = $booking_form_element.find('input[name="booking[payment_token]"]');
		  	if($payment_token_field.length){
			  	$booking_form_element.find('input[name="booking[payment_token]"]').val(result.paymentIntent.id);
    		}else{
    			// create payment token field if it doesn ot exist (when payment step is skipped)
    			$booking_form_element.find('.latepoint-booking-params-w').append('<input type="hidden" value="' + result.paymentIntent.id +'" name="booking[payment_token]" class="latepoint_payment_token"/>');
    		}
      	deferred.resolve();
		  }
		});
	  return deferred;
	}


	// Initiates credit card capture form
	initCreditCardForm() {
	}

}


let latepointPaymentsRazorpayAddon = new LatepointPaymentsRazorpayAddon(latepoint_helper.razorpay_key);